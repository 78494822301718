.scroll-to-top {
    $color: $color-white;
    $bg-color: $color-blue-dark;

    @include arrow('before', 13px, 13px, 'top', $color, 15px, 5px);
    display: none;
    background-color: $bg-color;
    border: 2px solid $bg-color;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 9900;
    transition: transform 0.3s ease-in-out;

    &:hover,
    &:focus-within {
        transform: scale(0.93);
    }
}
