.ce-bodytext {
    ul,
    ol {
        margin: 0 0 30px 0;
        padding: 0;

        li {
            margin: 0 0 10px 0;
            padding: 0;
        }

        ul,
        ol {
            margin: 5px 0 0 0;
        }

    }

    ul {
        list-style: none;

        li {
            padding-left: 25px;
            position: relative;

            &::before {
                background-color: $color-black;
                border-radius: 50%;
                content: '';
                display: block;
                height: 10px;
                left: 0;
                position: absolute;
                top: 8px;
                width: 10px;
            }

        }
    }

    ol {
        padding-left: 20px;
    }
}

.social-list,
.page-main .social-list {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;

    li {
        padding: 0;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        &::before {
            display: none;
        }
    }

    svg {
        display: inline-block;
        width: 50px;
        height: 50px;
        transition: transform .2s linear;
    }

    a {
        display: block;

        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }
}

