// Spacings for Sections
.section {
    --spacing-before: #{$s2-spacing-size};
    --spacing-after: #{$s2-spacing-size};

    @media (min-width: mq(sm)) {
        --spacing-before: #{$s2-spacing-size-desktop};
        --spacing-after: #{$s2-spacing-size-desktop};
    }

    margin: var(--spacing-before) 0 var(--spacing-after);

    &[class*='has-background-']:not(.has-background-white) {
        padding: var(--spacing-before) 0 var(--spacing-after);
        margin: 0;
    }

    &--space-before-none {
        --spacing-before: 0;
    }

    &--space-after-none {
        --spacing-after: 0;
    }

    &--space-before-small {
        --spacing-before: #{$s3-spacing-size};

        @media (min-width: mq(sm)) {
            --spacing-before: #{$s3-spacing-size-desktop};
        }
    }

    &--space-after-small {
        --spacing-after: #{$s3-spacing-size};

        @media (min-width: mq(sm)) {
            --spacing-after: #{$s3-spacing-size-desktop};
        }
    }

    &--space-before-medium {
        --spacing-before: #{$s2-spacing-size};

        @media (min-width: mq(sm)) {
            --spacing-before: #{$s2-spacing-size-desktop};
        }
    }

    &--space-after-medium {
        --spacing-after: #{$s2-spacing-size};

        @media (min-width: mq(sm)) {
            --spacing-after: #{$s2-spacing-size-desktop};
        }
    }

    &--space-before-large {
        --spacing-before: #{$s1-spacing-size};

        @media (min-width: mq(sm)) {
            --spacing-before: #{$s1-spacing-size-desktop};
        }
    }

    &--space-after-large {
        --spacing-after: #{$s1-spacing-size};

        @media (min-width: mq(sm)) {
            --spacing-after: #{$s1-spacing-size-desktop};
        }
    }
}

// Spacings for Content-Elements
.frame {
    &--space-before-none {
        margin-top: 0;
    }

    &--space-after-none {
        margin-bottom: 0;
    }

    &--space-before-small {
        margin-top: $s4-spacing-size;

        @media (min-width: mq(sm)) {
            margin-top: $s4-spacing-size-desktop;
        }

    }

    &--space-after-small {
        margin-bottom: $s4-spacing-size;

        @media (min-width: mq(sm)) {
            margin-bottom: $s4-spacing-size-desktop;
        }
    }

    &--space-before-medium {
        margin-top: $s3-spacing-size;

        @media (min-width: mq(sm)) {
            margin-top: $s3-spacing-size-desktop;
        }

    }

    &--space-after-medium {
        margin-bottom: $s3-spacing-size;

        @media (min-width: mq(sm)) {
            margin-bottom: $s3-spacing-size-desktop;
        }
    }

    &--space-before-large {
        margin-top: $s2-spacing-size;

        @media (min-width: mq(sm)) {
            margin-top: $s2-spacing-size-desktop;
        }

    }

    &--space-after-large {
        margin-bottom: $s2-spacing-size;

        @media (min-width: mq(sm)) {
            margin-bottom: $s2-spacing-size-desktop;
        }
    }

}
