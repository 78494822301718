.card {
    $card-shadow:  0px 10px 20px 0px rgba(0, 0, 0, 0.13);
    $card-title-hover-color: $link-color;

    background-color: $color-white;
    box-shadow: $card-shadow;
    margin: 0 auto 30px auto;

    > a {
        color: inherit;
        display: block;
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: none;

            .card__title {
                color: $link-color;
            }
        }
    }

    &__image {
        position: relative;

        img {
            width: inherit;
            height: inherit;
            object-fit: cover;
            object-position: center;
        }
    }

    figure {
        margin-bottom: 0;
    }

    &__image,
    figure,
    picture {
        width: 100%;
        height: 100%;
    }

    &__content {
        padding: 20px;
    }

    &__title {
        display: block;
        font-family: $font-headline;
        font-weight: $font-weight-bold;
        color: $text-color;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    &--left {
        @media (min-width: mq(sm)) {
            .card__content {
                padding-left: 0;
            }
        }
    }

    &--right {
        @media (min-width: mq(sm)) {
            .card__content {
                padding-right: 0;
            }
        }
    }
}
