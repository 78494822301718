.teaser {
    $teaser-title-hover-color: $link-color;

    margin: 0 auto 30px auto;

    > a {
        color: inherit;
        display: block;
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: none;

            .teaser__title {
                color: $link-color;
            }
        }
    }

    &__image {
        position: relative;

        img {
            width: 100%;
            height: auto;
        }
    }

    figure {
        margin-bottom: 0;
    }

    &__content {
        padding-top: 20px;
    }

    &__title {
        display: block;
        font-family: $font-headline;
        font-weight: $font-weight-bold;
        color: $text-color;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    &--left,
    &--right {
        @media (min-width: mq(sm)) {
            .teaser__content {
                padding-top: 0;
            }
        }
    }
}
