.page-footer {
    background: $color-red;
    font-size: $font-grid-10;
    color: $color-white;
    text-align: center;
    padding-bottom: 20px;

    @media (min-width: mq(xl)) {
        text-align: left;
    }

    .row {
        align-items: center;
    }

    &__nav {
        list-style: none;
        margin: 30px 0;
        padding: 0;

        @media (min-width: mq(xl)) {
            padding-top: 20px;
            text-align: right;
        }
    }

    li {
        display: inline-block;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        color: $color-white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        display: block;
    }

    &__copyright {
        @media (min-width: mq(xl)) {
            padding-top: 20px;
        }
    }

}
