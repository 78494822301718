/* Globale Mixins, die in mehreren Komponenten verwendet werden */

@mixin clearfix {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

/* Pfeil */
@mixin arrow-base($direction, $color, $size, $thickness) {
    $rotation: 225deg;
    border-bottom: $thickness solid $color;
    border-right: $thickness solid $color;
    content: '';
    display: block;
    height: $size;
    pointer-events: none;
    position: absolute;
    transform-origin: 66% 66%;
    transition: transform 0.25s ease-in-out;
    width: $size;

    @if $direction == 'top' {
        transform: rotate(#{$rotation});
    }
    @else if $direction == 'right' {
        transform: rotate(#{$rotation + 90});
    }
    @else if $direction == 'down' {
        transform: rotate(#{$rotation + 180});
    }
    @else if $direction == 'left' {
        transform: rotate(#{$rotation + 270});
    }
}

@mixin arrow($pseudo-element: 'after', $position-y: 0, $position-x: 0, $direction: 'right', $color: #000, $size: 12px, $thickness: 3px) {
    @if $pseudo-element == 'before' {
        &::before {
            @include arrow-base($direction, $color, $size, $thickness);
            left: $position-x;
            top: $position-y;
        }
    }
    @else if $pseudo-element == 'after' {
        &::after {
            @include arrow-base($direction, $color, $size, $thickness);
            right: $position-x;
            top: $position-y;
        }
    }
}
