.hamburger {
    // lokale Variablen
    $hamburger-padding-x: 6px;
    $hamburger-padding-y: 12px;
    $hamburger-layer-width: 38px;
    $hamburger-layer-height: 5px;
    $hamburger-layer-spacing: 6px;
    $hamburger-layer-color: $color-gray-dark;
    $hamburger-layer-border-radius: 0;
    $hamburger-hover-opacity: 0.7;
    $hamburger-hover-transition-duration: 0.15s;
    $hamburger-hover-transition-timing-function: linear;
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: inline-block;

    // Normalize Button
    font: inherit;
    margin: 0;
    overflow: visible;

    // generelle Styles
    padding: $hamburger-padding-y $hamburger-padding-x;
    text-transform: none;
    transition-duration: $hamburger-hover-transition-duration;
    transition-property: opacity, filter;
    transition-timing-function: $hamburger-hover-transition-timing-function;

    .hamburger-box {
        display: inline-block;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        position: relative;
        width: $hamburger-layer-width;
    }

    .hamburger-inner {
        display: block;
        margin-top: $hamburger-layer-height / -2;
        top: 50%;
        transition-duration: 0.1s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &,
        &::before,
        &::after {
            background-color: $hamburger-layer-color;
            border-radius: $hamburger-layer-border-radius;
            height: $hamburger-layer-height;
            position: absolute;
            transition-duration: 0.15s;
            transition-property: transform;
            transition-timing-function: ease;
            width: $hamburger-layer-width;
        }

        &::before,
        &::after {
            content: ' ';
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            transition: top 0.1s 0.14s ease, opacity 0.1s ease;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.is-active {
        @supports (display: flex) {
            .hamburger-inner {
                transform: rotate(45deg);
                transition-delay: 0.14s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

                &::before {
                    opacity: 0;
                    top: 0;
                    transition: top 0.1s ease, opacity 0.1s 0.14s ease;
                }

                &::after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
        }
    }
}
