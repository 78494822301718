/*
  Definiert Breakpoint-Namen, um diese per JavaScript importieren zu können
  vgl. https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
*/

body {
    &::before {
        content: 'xs';
        display: none;
        @media (min-width: mq(sm)) {
            content: 'sm';
        }
        @media (min-width: mq(md)) {
            content: 'md';
        }
        @media (min-width: mq(lg)) {
            content: 'lg';
        }
        @media (min-width: mq(xl)) {
            content: 'xl';
        }
    }
}
