.list-check,
.ce-bodytext .list-check {
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0;

    li {
        margin-bottom: 10px;
        padding-left: 30px;
        position: relative;

        &::before {
            @include icon-list-check;
            background-color: transparent;
            border-radius: 0;
            content: '';
            display: inline-block;
            height: 16px;
            left: 0;
            position: absolute;
            top: 6px;
            width: 19px;

            @media (min-width: mq(xl)) {
                top: 10px;
            }
        }

    }
}
