body {
    font-family: $font-content;
    font-size: $font-size-root;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 1.7;

    @media (min-width: mq(xl)) {
        font-size: $font-size-root-desktop;
    }
}

strong {
    font-weight: $font-weight-bold;
}

a {
    color: $link-color;
    cursor: pointer;
    transition: color .2s ease;

    &:hover {
        color: $link-color;
        text-decoration: underline;
    }
}

p {
    margin-bottom: 30px;
}

hr {
    border-color: $color-gray;
    margin: 30px 0;
}

blockquote {
    border-left: 4px solid $color-black;
    color: $blockquote-color;
    font-style: italic;
    padding-left: 30px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    display: block;
    font-family: $font-headline;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    letter-spacing: 0.5px;
    margin-bottom: 15px;

    @media (max-width: mq(md)) {
        hyphens: auto;
    }
}

h1,
.h1 {
    color: $primary-color;
    margin-bottom: 20px;

    @media (min-width: mq(xl)) {
        font-size: $h1-font-size-desktop;
        margin-bottom: 30px;
    }
}

h2,
.h2 {
    @media (min-width: mq(xl)) {
        font-size: $h2-font-size-desktop;
        margin-bottom: 20px;
    }
}

h3,
.h3 {
    @media (min-width: mq(xl)) {
        font-size: $h3-font-size-desktop;
    }
}

h4,
.h4 {
    @media (min-width: mq(xl)) {
        font-size: $h4-font-size-desktop;
    }
}

h5,
.h5 {
    @media (min-width: mq(xl)) {
        font-size: $h5-font-size-desktop;
    }
}

h6,
.h6 {
    @media (min-width: mq(xl)) {
        font-size: $h6-font-size-desktop;
    }
}

.text-large {
    font-size: $text-large-font-size;
    margin-bottom: 20px;

    @media (min-width: mq(xl)) {
        font-size: $text-large-font-size-desktop;
        margin-bottom: 30px;
    }
}

.text-small {
    font-size: $text-small-font-size;
    line-height: 1.5;
    margin-bottom: 15px;

    @media (min-width: mq(xl)) {
        font-size: $text-small-font-size-desktop;
        margin-bottom: 20px;
    }
}

