.hero {
    $hero-content-bg-mobile: $color-white;
    $hero-content-bg-desktop: rgba(255, 255, 255, 0.8);

    flex-shrink: 0;
    position: relative;
    width: 100%;
    margin: 0 auto;

    .container {

        @media (min-width: mq(lg)) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__image {
        display: flex;

        @media (min-width: mq(lg)) {
            position: relative;

            figure,
            picture {
                width: 100%;
                margin-bottom: 0;
            }

            picture {
                display: block;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &__foreground {
        position: relative;
        z-index: 2;
        margin: 0;

        @media (min-width: mq(lg)) {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 50px 0;
            margin: 0;
        }
    }

    &__content {
        background-color: $hero-content-bg-mobile;
        box-shadow:  0px 10px 20px 0px rgba(0, 0, 0, 0.13);
        text-align: center;
        padding: 20px;

        @media (min-width: mq(lg)) {
            background-color: $hero-content-bg-desktop;
            max-width: 480px;
        }

        p {
            margin-bottom: 10px;

            &:last-child {
                margin: 0;
            }
        }

        .btn {
            margin-bottom: 0;
        }
    }

    &__title {
        margin-bottom: 10px;
    }
}

