
////// GENERAL STYLES

// Grid
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

$grid-gutter-width:         30px;
$page-wrapper-width:        1920px;

// Header
$header-height: 80px;
$header-height-shrinked: 60px;

////// FONTS

// font-family
$font-headline:                     Verdana, Helvetica, Arial, sans-serf;
$font-content:                      Verdana, Helvetica, Arial, sans-serf;

// font weights
$font-weight-bold:                  700;
$font-weight-normal:                400;

// >> font grid << Please use only $front-grid-xx variables in your components
$font-grid-70:                      58px;
$font-grid-60:                      36px;
$font-grid-50:                      28px;
$font-grid-40:                      24px;
$font-grid-30:                      21px;
$font-grid-20:                      18px;
$font-grid-10:                      16px;
$font-grid-5:                       14px;

// font-sizes
$font-size-root:                    1.125rem;   // 18px
$font-size-root-desktop:            $font-grid-30;

$h1-font-size:                      $font-grid-50;
$h1-font-size-desktop:              $font-grid-60;

$h2-font-size:                      $font-grid-40;
$h2-font-size-desktop:              $font-grid-50;

$h3-font-size:                      $font-grid-30;
$h3-font-size-desktop:              $font-grid-40;

$h4-font-size:                      $font-grid-20;
$h4-font-size-desktop:              $font-grid-30;

$h5-font-size:                      $font-grid-10;
$h5-font-size-desktop:              $font-grid-20;

$h6-font-size:                      $font-grid-5;
$h6-font-size-desktop:              $font-grid-10;

$text-large-font-size:              $font-grid-30;
$text-large-font-size-desktop:      $font-grid-40;

$text-small-font-size:              $font-grid-5;
$text-small-font-size-desktop:      $font-grid-10;

////// COLORS
// edit colors in frontend/pattern-library/src/data/toolkit.yml to show in pattern library
$color-white:               #fff;
$color-black:               #000;

$color-gray-lighter:        #eee;
$color-gray-light:          #ccc;
$color-gray:                #999;
$color-gray-dark:           #666;
$color-gray-darker:         #333;

$color-red:                 #d53015;
$color-blue:                #0163ab;
$color-blue-dark:           #334161;
$color-blue-light:          #e9edf7;

$success-color:             $color-blue;
$error-color:               $color-red;

$color-body-background:     $color-white;

$primary-color:             $color-blue;
$secondary-color:             $color-red;

$text-color:                $color-gray-darker;
$link-color:                $primary-color;
$blockquote-color:          $text-color;

$bg-colors: (
    'white':                $color-white,
    'blue-light':            $color-blue-light,
    'gray':                 $color-gray-light,
);

////// FORM
$form-active-color:         $primary-color;
$form-error-color:          $error-color;
$form-border-width:         2px;
$form-input-height:         45px;

// SPACINGS

// >> spacing grid <<
$spacing-grid-10:   10px;
$spacing-grid-20:   20px;
$spacing-grid-40:   40px;
$spacing-grid-80:   80px;
$spacing-grid-100:  100px;
$spacing-grid-160:  160px;

// spacing-sizes
$s1-spacing-size:            $spacing-grid-80;
$s1-spacing-size-desktop:    $spacing-grid-160;

$s2-spacing-size:            $spacing-grid-40;
$s2-spacing-size-desktop:    $spacing-grid-80;

$s3-spacing-size:            $spacing-grid-20;
$s3-spacing-size-desktop:    $spacing-grid-40;

$s4-spacing-size:            $spacing-grid-10;
$s4-spacing-size-desktop:    $spacing-grid-20;
