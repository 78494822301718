// There is no way at the moment to define dynamic mixin names
@mixin icon-data-image($icon) {
    // This gets inlined as data by the postcss-assets plugin
    background-image: inline('../../Images/svg-data-images/' + $icon + '.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

@mixin icon-file-pdf {
    @include icon-data-image(file-pdf);
}

@mixin icon-form-check {
    @include icon-data-image(form-check);
}

@mixin icon-list-check {
    @include icon-data-image(list-check);
}

@mixin icon-list-download {
    @include icon-data-image(list-download);
}

@mixin icon-list-link {
    @include icon-data-image(list-link);
}

@mixin icon-arrow-down {
    @include icon-data-image(arrow-down);
}
