.page-header {
    $header-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.1);
    $header-shadow-secondlevel: 2px 4px 3px rgba(0, 0, 0, 0.3);
    $header-transition: 0.3s linear;
    $header-color-text: $color-gray;
    $offcanvas-width: 280px;
    $offcanvas-transition: transform 0.35s ease-out;
    $offcanvas-item-height: 52px;
    $offcanvas-item-border: #696f74;
    $offcanvas-color-background: #50575d;
    $offcanvas-color-nextlevel: #7d868d;
    $offcanvas-color-hover: #44474a;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    height: $header-height;
    background: $primary-color;
    box-shadow: $header-shadow;
    z-index: 9000;
    transition: height $header-transition;
    transition-delay: 0.025s;
    margin: 0 auto;

    .wrapper {
        @media (min-width: mq(xl)) {
            background-color: $primary-color;
        }
    }

    .logo {
        display: inline-block;
        height: inherit;
        line-height: ($header-height - 1);
        transition: line-height $header-transition;

        img {
            width: 440px;
            transition: height $header-transition, width $header-transition;
        }
    }

    .container {
        // extends Bootstrap's container
        position: relative;
        height: $header-height;
        transition: height $header-transition;
        @media (min-width: mq(lg)) {
            width: 100%;
        }
    }

    .mobile-toggle {
        // see 03-components/_hamburger-icon for the icon styles
        position: absolute;
        top: calc(50% - 25px);
        right: 10px;
        width: 50px;
        height: 50px;
        @media (min-width: mq(xl)) {
            display: none;
        }
    }

    .navigation {
        display: none;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        // Mobile: offcanvas left with two levels
        @media (max-width: mq(xl)) {
            position: fixed;
            top: $header-height-shrinked;
            left: 0;
            width: $offcanvas-width;
            height: 100%;
            background-color: $offcanvas-color-background;
            border-top: 2px solid $offcanvas-item-border;
            transform: translate3d(-$offcanvas-width, 0, 0);
            transition: $offcanvas-transition, top $header-transition;
            z-index: 9500;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;

            &.is-active {
                transform: translate3d(0, 0, 0);
            }

            &.show-second-level {
                .first-level,
                .back-level {
                    transform: translate3d(-$offcanvas-width, 0, 0);
                }
            }

            &.show-third-level {
                .first-level,
                .second-level,
                .back-level {
                    transform: translate3d(-$offcanvas-width, 0, 0);
                }
            }

            ul {
                min-height: 400px;
            }

            li {
                display: block;
                height: $offcanvas-item-height;
            }

            a,
            .link {
                display: block;
                padding: 0 10px;
                height: $offcanvas-item-height;
                line-height: $offcanvas-item-height;
                width: $offcanvas-width;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $color-white;
                font-size: 16px;
                font-weight: 600;
                border-bottom: 2px solid $offcanvas-item-border;

                &:hover,
                &:focus {
                    text-decoration: none;
                    background-color: $offcanvas-color-hover;
                }
            }

            .first-level {
                transition: $offcanvas-transition;

                > li {
                    position: relative;

                    &:nth-child(1) {
                        .second-level {
                            top: $offcanvas-item-height;
                        }

                        & > .third-level {
                            top: 0;
                        }
                    }

                    &:nth-child(2) {
                        .second-level {
                            top: 0;
                        }

                        & > .third-level {
                            top: -$offcanvas-item-height;
                        }
                    }
                    @for $i from 3 to 10 {
                        &:nth-child(#{$i}) {
                            .second-level {
                                top: (($i - 2) * $offcanvas-item-height) * -1;
                            }

                            & > .third-level {
                                top: $offcanvas-item-height * (($i - 1) * -1);
                            }
                        }
                    }
                }
            }

            .second-level,
            .third-level {
                display: none;
                position: absolute;
                top: 0;
                left: $offcanvas-width;
                width: $offcanvas-width;
                transition: $offcanvas-transition;

                &.is-active {
                    display: block;
                }
            }

            .third-level {
                .next-level {
                    display: none;
                }
            }

            .back-level {
                position: absolute;
                top: 0;
                left: $offcanvas-width;
                width: $offcanvas-width;
                height: $offcanvas-item-height;
                background-color: $offcanvas-color-nextlevel;
                border-bottom: 2px solid $offcanvas-item-border;
                transition: $offcanvas-transition;

                .link {
                    @include arrow('before', 18px, 8px, 'left', $color-white, 10px, 3px);
                    padding-left: 28px;
                }
            }

            .next-level {
                @include arrow('after', 18px, 22px, 'right', $color-white, 10px, 3px);
                position: absolute;
                top: 0;
                right: 0;
                width: $offcanvas-item-height;
                height: $offcanvas-item-height;
                background-color: $offcanvas-color-nextlevel;
                border-bottom: 2px solid $offcanvas-item-border;
                cursor: pointer;

                &:hover {
                    background-color: $offcanvas-color-hover;
                }
            }
        }
        // Desktop: horizontal with flyouts
        @media (min-width: mq(xl)) {
            display: none;

            .first-level {
                display: block;
                float: right;
                width: auto;
                padding: 0;
                margin: 0;
                height: $header-height;

                > li {
                    position: relative;
                    float: left;
                    height: inherit;

                    &:hover,
                    &.is-hovered {
                        & > a {
                            background-color: $color-gray-light;
                            overflow: hidden;
                        }

                        .second-level {
                            top: $header-height;
                            transition: top $header-transition;
                        }
                    }

                    &.is-hovered {
                        & > a {
                            &::after {
                                content: ' ';
                                display: block;
                                position: absolute;
                                top: $header-height - 10px;
                                left: calc(50% - 10px);
                                width: 20px;
                                height: 20px;
                                background: linear-gradient(135deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 50%);
                                transform: rotate(45deg);
                            }
                        }
                    }

                    & > a {
                        display: block;
                        height: $header-height;
                        padding: 0 24px;
                        line-height: $header-height + 2px;
                        font-size: $h3-font-size;
                        font-weight: 400;
                        color: $header-color-text;
                        // background-color: $color-white;
                        text-decoration: none;
                        transition: height $header-transition, line-height $header-transition, font-size $header-transition;

                        &:hover,
                        &.active {
                            background-color: $color-gray-light;
                        }
                    }
                }
            }

            .second-level {
                position: absolute;
                top: -1000%;
                min-width: 300px;
                width: max-content;
                background-color: $color-white;
                box-shadow: $header-shadow-secondlevel;
                z-index: -1;
                transition: top 0.75s ease-out;
                border: 1px solid $color-white;

                > li {
                    position: relative;
                    border-bottom: 1px solid $color-gray-light;
                    line-height: 1.3;

                    &:first-child {
                        .next-level-desktop {
                            @include arrow('after', 21px, 30px, 'down', $offcanvas-color-nextlevel, 10px, 3px);
                            transition: $offcanvas-transition;

                            &.is-active {
                                &::after {
                                    transform: rotate(225deg);
                                    transition: $offcanvas-transition;
                                }
                            }
                        }
                    }

                    &:last-child {
                        border: 0;
                    }

                    &:hover {
                        .third-level {
                            > li {
                                background-color: $color-white;

                                &:hover {
                                    background-color: $color-gray-light;

                                }
                            }
                        }
                    }
                }

                > li > a {
                    position: relative;
                    z-index: 1;
                    display: block;
                    font-size: $h4-font-size;
                    color: $header-color-text;
                    padding: 13px 77px 11px 25px;

                    &:hover {
                        text-decoration: none;
                        background-color: $color-gray-light;
                    }

                    &.active {
                        color: $color-red;
                    }
                }
            }

            .third-level {
                position: relative;
                z-index: 3;
                max-height: 0;
                overflow: hidden;
                transition: max-height $header-transition;

                &.is-active {
                    max-height: 250px;
                }

                > li {
                    border-top: 1px solid #e9edef;

                    &:hover {
                        background-color: $color-gray-light;

                    }
                }

                > li > a {
                    display: block;
                    font-size: $h5-font-size;
                    color: $header-color-text;
                    padding: 14px 65px 13px 35px;

                    &:hover {
                        text-decoration: none;
                    }

                    &.active {
                        color: $color-red;
                    }
                }
            }

            .next-level-desktop {
                @include arrow('after', 16px, 30px, 'down', $offcanvas-color-nextlevel, 10px, 3px);
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                width: $offcanvas-item-height + 15px;
                height: 100%;
                background-color: $color-white;
                //border-bottom: 2px solid $offcanvas-item-border;
                cursor: pointer;
                transition: $offcanvas-transition;

                &:hover {
                    background-color: $color-gray-light;
                }

                &.is-active {
                    &::after {
                        transform: rotate(225deg);
                        transition: $offcanvas-transition;
                    }
                }
            }

            .back-level,
            .next-level {
                display: none;
            }
        }
    }

    /* Mobile and shrinked header */

    @mixin shrinked-header {
        height: $header-height-shrinked;

        .container {
            height: $header-height-shrinked;
        }

        .logo {
            line-height: ($header-height-shrinked - 1);

            img {
                width: 320px;
            }
        }
    }

    @media (max-width: mq(xl)) {
        @include shrinked-header;
    }

    &.is-shrinked {
        @include shrinked-header;

        .navigation {
            @media (min-width: mq(xl)) {
                .first-level {
                    height: $header-height-shrinked;

                    > li {
                        position: relative;
                        float: left;
                        height: $header-height-shrinked;

                        &.is-hovered {
                            .second-level {
                                top: $header-height-shrinked;
                            }

                            & > a {
                                &::after {
                                    top: $header-height-shrinked - 8px;
                                }
                            }
                        }

                        & > a {
                            height: $header-height-shrinked;
                            line-height: $header-height-shrinked;
                            font-size: $h4-font-size;
                        }
                    }
                }
            }
        }
    }

}
