button {
    font-family: inherit;
    font-size: inherit;
}

.btn {
    background-color: $color-gray-dark;
    color: $color-white;
    border: 0;
    border-radius: 9px;
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background: darken($color-gray-dark, 5%);
        color: $color-white;
        text-decoration: none;
    }

    &--primary {
        background: $primary-color;
        color: $color-white;

        &:hover {
            background: darken($primary-color, 5%);
            color: $color-white;
        }
    }

    &--secondary {
        background: $secondary-color;
        color: $color-white;

        &:hover {
            background: darken($secondary-color, 10%);
            color: $color-white;
        }
    }
}

.btn-group {
    .btn {
        margin: 0 10px 10px 0;

        &:last-child {
            margin-right: 0;
        }
    }
}
