/**
 * Bootstrap 4
 * Lädt benötigte Bootstrap-Komponenten aus /node_modules
 * Ohne normalize, da diese out of date ist.
 */

@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../../node_modules/bootstrap/scss/utilities';
@import '../../../../../node_modules/bootstrap/scss/reboot';
@import '../../../../../node_modules/bootstrap/scss/type';
@import '../../../../../node_modules/bootstrap/scss/images';
@import '../../../../../node_modules/bootstrap/scss/containers';
@import '../../../../../node_modules/bootstrap/scss/grid';
@import '../../../../../node_modules/bootstrap/scss/tables';
@import '../../../../../node_modules/bootstrap/scss/forms';
@import '../../../../../node_modules/bootstrap/scss/breadcrumb';
@import '../../../../../node_modules/bootstrap/scss/utilities/api';

// AOS, Animation Library
@import '../../../../../node_modules/aos/src/sass/aos';
