.page-main {
    &::before {
        content: '';
        display: block;
        height: $header-height-shrinked;
        width: 100%;

        @media (min-width: mq(xl)) {
            height: $header-height;
        }
    }
}

.section {
    &--bg-white {
        background-color: $color-blue-light;
    }

    &--bg-gray {
        background-color: $color-gray-lighter;
    }
    &--parallax-window {
        background: transparent;
        color: $color-white;
        min-height: 10px;
        padding: 80px 0;
        margin: 0;

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $color-white;
        }

        a {
            &:not(.btn) {
                color: $color-white;
                text-decoration: underline;
            }
        }

        p {
            margin: 30px 0;
        }

        .btn {
            @media (min-width: mq(md)) {
                font-size: $font-grid-30;
            }
        }
    }
}

.container {
    &--offset {
        max-width: 730px;
        margin: 0 auto;
    }
}
